import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BookList from './components/BookList';
import BookDetails from './components/BookDetails';
import './App.css';

const FeedbackBanner = () => (
  <div className="feedback-banner">
    <a href="https://forms.gle/sNCUCyAVSwpwonc37" target="_blank" rel="noopener noreferrer">
      Click here to share your feedback.
    </a>
  </div>
);

function App() {
  return (
    <Router>
      <div className="App">
        <FeedbackBanner />
        <header className="App-header">
          <h1>Book Summaries</h1>
        </header>
        <main>
          <Switch>
            <Route exact path="/" component={BookList} />
            <Route path="/book/:id" component={BookDetails} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
