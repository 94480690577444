import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function BookList() {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    fetch('/data/books.json')
      .then(response => response.json())
      .then(data => setBooks(data))
      .catch(error => console.error('Error fetching books:', error));
  }, []);

  return (
    <div className="book-list">
      <div className="book-grid">
        {books.map(book => (
          <Link to={`/book/${book.book_id}`} key={book.book_id} className="book-item">
            <div className="book-info">
              <h3 className="book-title">{book.title}</h3>
              <p className="book-author">by {book.author}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BookList;