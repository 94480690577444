import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

function BookDetails() {
  const { id } = useParams();
  const [bookData, setBookData] = useState(null);
  const [summary, setSummary] = useState('');
  const [summarySize, setSummarySize] = useState(1);
  const [audioUrl, setAudioUrl] = useState(null);

  useEffect(() => {
    fetch(`/data/book_${id}.json`)
      .then(response => response.json())
      .then(data => {
        setBookData(data);
        setSummary(data.default_summary);
        setSummarySize(data.default_size);
        setAudioUrl(`/audio/book_${id}_${data.default_size}min.mp3`);
      })
      .catch(error => console.error('Error fetching book details:', error));
  }, [id]);

  const handleSummaryChange = (size) => {
    setSummarySize(size);
    fetch(`/data/book_${id}_summary_${size}.json`)
      .then(response => response.json())
      .then(data => {
        setSummary(data.summary);
        setAudioUrl(`/audio/book_${id}_${size}min.mp3`);
      })
      .catch(error => console.error('Error fetching summary:', error));
  };

  if (!bookData) return <div>Loading...</div>;

  return (
    <div className="book-details">
      <Link to="/" className="back-button">← Back to Book List</Link>
      <h2>{bookData.metadata.title}</h2>
      <div className="summary-control">
        <button
          className={`summary-button ${summarySize === 1 ? 'active' : ''}`}
          onClick={() => handleSummaryChange(1)}
        >
          1 min summary
        </button>
        <button
          className={`summary-button ${summarySize === 3 ? 'active' : ''}`}
          onClick={() => handleSummaryChange(3)}
        >
          3 min summary
        </button>
      </div>

      <div className="audio-player">
        <h3>Listen to Summary</h3>
        <audio controls src={audioUrl}>
          Your browser does not support the audio element.
        </audio>
        <p className="audio-note">*voice is AI generated</p>
      </div>
      
      <div className="summary">
        <h3>Summary</h3>
        <ReactMarkdown>{summary}</ReactMarkdown>
      </div>
    </div>
  );
}

export default BookDetails;